import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { ServerService } from '../services/server.service';
import { DialpadComponent } from '../mc/dialpad/dialpad.component';
declare var $:any;
declare var iziToast:any;
declare var medi: any;
declare var dialPadOpen: any;
import Swal from 'sweetalert2'
@Component({
  selector: 'app-edit-contacts',
  templateUrl: './edit-contacts.component.html',
  styleUrls: ['./edit-contacts.component.css']
})
@Pipe({
  name: 'safe'
})
export class EditContactsComponent implements OnInit {




  param1: string;
  created_time;
  modified_time;
  contact_id;
  
  departments;
  uadmin_id;
  auxcodes;
  fromEdit;
  questions;
  to_num;
  call_record_id;
  admin_id;
  dailyfood = false;
  alladmin = true;
  dsk_access;
  hasdsk = false
  hasnodsk = true;
  external_contact_url;
  public allowMp = true;
  public startrec = false;
  dailyfoodurl;
  has_external_contact;
  IFlink;
  show_caller_id;
  hide_cal1er = '0';
  click_to_call_number;
  click_to_call_mobile_number;
  click_to_call_office_phone;
  click_to_call_home_phone;
  crm_type;
  hapikey;
  paramq;
  dailyfoodurlhub;
  z_authId;
  z_orgId;
  paramCall;
  popupnumber;
  MDy_bearer_token;
  contact_name;
  phone_activity;
  websocket;
  extension;
  has_hard_id;
  recordNotFound;
  single_timeline;
  Tsubject;
  Tdescription;
  Tphone;
  Trecord;
  Twrapcode;
  Twrapnote;
  user_type;
  no_contact=false;
  auxcode_catagory;
  category_name;
  constructor(private serverService: ServerService, private router:Router,private route: ActivatedRoute, private sanitizer: DomSanitizer) { 
    
    this.param1 = this.route.snapshot.queryParamMap.get('phone');
   
    this.paramCall = this.route.snapshot.queryParamMap.get('calltype');
    
    var decodedString = atob(this.param1);
    this.param1 = decodedString;   
    
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    
  }
  
  ngOnInit() {
  
     this.uadmin_id = localStorage.getItem('userId');
     this.show_caller_id = localStorage.getItem('show_caller_id');
    this.extension = localStorage.getItem('ext_num');
    this.has_hard_id = localStorage.getItem('hardware_id');
    // let user_id: any=localStorage.getItem('userId');

    $('#user_number').val(this.extension);
    //  this.getDepartments();
    //  this.getAuxCode();
     this.editConatcts();
   this.initSocket();
    // if(this.fromEdit){
     
    // } else {
    //   this.getquestionnaire();
    // }
    
    this.admin_id = localStorage.getItem('admin_id');
    this.dsk_access = localStorage.getItem('dsk_access');
    this.has_external_contact = localStorage.getItem('has_external_contact');
    this.user_type= localStorage.getItem('user_type');
      
  }

  ngAfterViewInit() {
    // if(this.crm_type == 'SalesForce' ){
    //   this.dailyfoodurl = this.external_contact_url;
    // } else if(this.crm_type == 'HubSpot'){
     
    // }


    if(this.crm_type == 'SalesForce' ){
      this.dailyfoodurl = this.external_contact_url;
      $('<iframe is="x-frame-bypass" class="dailyfIfram" width="100%" height="800px" frameborder="0" allowfullscreen ></iframe>').attr('src', this.dailyfoodurl).appendTo('#dailyfIframes'); 

    } else if(this.crm_type == 'HubSpot'){
      this.dailyfoodurl = this.external_contact_url;
      let access_token: any=localStorage.getItem('access_token');

      if(this.paramq ==''){
        this.dailyfoodurl = this.external_contact_url;
      } else {
        this.dailyfoodurl = 'https://api.hubapi.com/contacts/v1/search/query?q='+this.paramq+'&hapikey='+this.hapikey;
      }
      

      let api_req:any = '{"operation":"agents", "moduleType":"agents", "api_type": "web", "access_token":"'+access_token+'", "element_data":{"action":"curl_response","url":"'+this.dailyfoodurl+'"}}';

      this.serverService.sendServer(api_req).subscribe((response:any) => {
      //  console.log(response.contacts[0]);
        this.dailyfoodurlhub = response.contacts[0]['profile-url'];
       // alert(this.dailyfoodurlhub);
        $('<iframe  class="dailyfIfram" width="100%" height="800px" frameborder="0" allowfullscreen ></iframe>').attr('src', this.dailyfoodurlhub).appendTo('#dailyfIframes'); 
      }, 
      (error)=>{
          console.log(error);
      });  
    }  else if(this.crm_type == 'ZohoDesk'){
      this.dailyfoodurl = this.external_contact_url;
      let access_token: any=localStorage.getItem('access_token');
      this.z_authId  = this.route.snapshot.queryParamMap.get('authkey');
      this.z_orgId  = this.route.snapshot.queryParamMap.get('orgId');
      var number  = this.route.snapshot.queryParamMap.get('q');


      if(this.paramq ==''){
        this.dailyfoodurl = this.external_contact_url;
      } else {
        this.dailyfoodurl = 'https://desk.zoho.com/api/v1/search?module=contacts&searchStr='+this.paramq;
      }
      

      let api_req:any = '{"operation":"agents", "moduleType":"agents", "api_type": "web", "access_token":"'+access_token+'", "element_data":{"action":"curl_response_zoho_desk","url":"'+this.dailyfoodurl+'","authkey":"'+this.z_authId+'","orgID":"'+this.z_orgId+'","number":"'+number+'"}}';

      this.serverService.sendServer(api_req).subscribe((response:any) => {
        this.dailyfoodurlhub = response.result.data;
     // alert(this.dailyfoodurlhub);
        $('<iframe is="x-frame-bypass" class="dailyfIfram" width="100%" height="800px" frameborder="0" allowfullscreen ></iframe>').attr('src', this.dailyfoodurlhub).appendTo('#dailyfIframes'); 
      }, 
      (error)=>{
          console.log(error);
      });  
    }


  }
tesr(){
  medi();
  this.allowMp = false;
  $("#btns").css("display", "block");
}

initSocket(){
   
  if(this.admin_id == '66'){
      this.websocket = new WebSocket("wss://myscoket.mconnectapps.com:4002"); 
    } else if(this.admin_id == '201'){
      this.websocket = new WebSocket("wss://myscoket.mconnectapps.com:4003"); 
    } else {
      this.websocket = new WebSocket("wss://myscoket.mconnectapps.com:4014"); 
    }


      this.websocket.onopen = function(event) { 
        console.log('Dialpad socket connected');
      }
  
      this.websocket.onmessage = function(event) {      
     console.log(event.data);  
      var result_message = JSON.parse(event.data);
  //    console.log(result_message);  
  //    console.log($('#user_number').val());
  this.has_hard_id = localStorage.getItem('hardware_id');
    if(result_message[0].cust_id == this.has_hard_id){
      console.log('matched');
    } else {
      console.log('not matched');
      return false;
    }

       if(result_message[0].data[0].wrapuptype=="wrapupcall_id" && result_message[0].data[0].extno == $('#user_number').val()){            
        $('#wrapup_callID').val(result_message[0].data[0].callid);
        // alert(result_message[0].data[0].callid)
        // alert('asnja')
        // $('#wrapup_callID').click();
        }

        
       
      }
  
      this.websocket.onerror = function(event){
        console.log('error');
      }
      this.websocket.onclose = function(event){
        console.log('close');
   $('#reconnect_socket').click();

  }




}
getquestionnaire(){
if(this.questions != null)
return false;

  let access_token: any=localStorage.getItem('access_token');
  let admin_id: any=localStorage.getItem('admin_id');
  let api_req:any = '{"operation":"questionaire", "moduleType":"questionaire", "api_type": "web", "access_token":"'+access_token+'", "element_data":{"action":"get_user_queue","user_id":"'+admin_id+'"}}';

  // this.serverService.sendServer(api_req).subscribe((response:any) => {
  //   if(response.status==true){
  //     // $('.settingSidebar').addClass('showSettingPanel');
  //     this.questions= response.result.data;
  //   } else {
  //   }
  // }, 
  // (error)=>{
  //     console.log(error);
  // });
}

closeQuestion(){
  $('.settingSidebar').removeClass('showSettingPanel');
}


toggleClass(){
  // this.getAuxCode();
  this.getAuxCatogory();
  if(this.fromEdit){
     
  } else {
    this.getquestionnaire();
  }
  
  $('.settingSidebar').toggleClass('showSettingPanel');
}




  getDepartments(){
    if(this.departments!=null)
       return false;

    let access_token: any=localStorage.getItem('access_token');
  
    let api_req:any = '{"operation":"ticket", "moduleType":"ticket", "api_type": "web", "access_token":"'+access_token+'", "element_data":{"action":"get_dept_settings","user_id":"'+this.admin_id+'"}}';
  
    this.serverService.sendServer(api_req).subscribe((response:any) => {
      if(response.result.status==true){
        this.departments = response.result.data;
      } else {
      }
    }, 
    (error)=>{
        console.log(error);
    });
  }
  getAuxCatogory(){
    
    if(this.auxcode_catagory!=null)
    return false;

    let access_token: any=localStorage.getItem('access_token');
  
    let api_req:any = '{"operation":"getAuxcode", "moduleType":"contact", "api_type": "web", "access_token":"'+access_token+'", "element_data":{"action":"get_aux_code_category","admin_id":"'+this.admin_id+'","user_id":"'+this.uadmin_id+'"}}';
  
    this.serverService.sendServer(api_req).subscribe((response:any) => {
      if(response.result.status==true){
        this.auxcode_catagory = response.result.data;
      } else {
      }
    }, 
    (error)=>{
        console.log(error);
    });
  }

  getAuxCode(){
    // if(this.auxcodes!=null)
    //  return false;
     let cat_id=$('#auxcodes_pop').val();
     this.getCatname(cat_id);
    let access_token: any=localStorage.getItem('access_token');
  
    let api_req:any = '{"operation":"getAuxcode", "moduleType":"contact", "api_type": "web", "access_token":"'+access_token+'", "element_data":{"action":"getuax_by_cat","cat_id":"'+cat_id+'","admin_id":"'+this.admin_id+'"}}';
  
    this.serverService.sendServer(api_req).subscribe((response:any) => {
      if(response.result.status==true){
        this.auxcodes = response.result.data;
      } else {
      }
    }, 
    (error)=>{
        console.log(error);
    });
  }
getCatname(id){
  let access_token: any=localStorage.getItem('access_token');
    let api_req:any = '{"operation":"getAuxcode_data", "moduleType": "contact", "api_type": "web", "access_token":"'+access_token+'", "element_data":{"action":"edit_aux_code_category","cat_id":"'+id+'","admin_id":"'+this.admin_id+'"}}';
  
    this.serverService.sendServer(api_req).subscribe((response:any) => {
      if(response.result.status == true){
        var agent_data = response.result.data;
        this.category_name=agent_data.category_name;
     
      } else{
        iziToast.warning({
            message: "Wrap Up codes not retrive. Please try again",
            position: 'topRight'
        });
    
  }
    }, 
    (error)=>{
        console.log(error);
    });
}
editConatcts(){
  // alert(this.paramCall);
  // alert(this.param1);
  let conct_req:any = new Object();
  let api_req_get_access:any = new Object();
  let api_req_get_contact:any = new Object();
  let api_req_get_activity:any= new Object();

  

  // alert(this.paramq);
  api_req_get_access.apiFor="getAcesstoken";


    this.serverService.MDy_Contacts_API(api_req_get_access).subscribe((response:any) => {     
      this.MDy_bearer_token=response.access_token;
  api_req_get_contact.apiFor="getContact_omni";
  api_req_get_contact.number=this.param1;
  api_req_get_contact.accessToken=this.MDy_bearer_token;

      this.serverService.MDy_Contacts_API(api_req_get_contact).subscribe((response:any) => {   

        if(response.data != ""){
          let value=response.data[0];
        $('#first_name').val(value.firstname);
        $('#last_name').val(value.lastname);
        $('#job_title').val(value.jobtitle);
        // $('#account_name').val(value.fullname);
        $('#email').val(value.emailaddress1);
        $('#business_phone').val(value.telephone1);
        $('#mobile_phone').val(value.mobilephone);   
        $('#fax').val(value.fax);   
        this.contact_name=value.fullname;
        } else {
          this.no_contact=true;
         
          Swal.fire({
            title: 'Contact Not Found',
            text: "You can save contact by hitting the Add contact button",
            icon: 'info',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',   
            confirmButtonText: 'Got it'
          })  
          $('#first_name').val();
          $('#last_name').val();
          $('#job_title').val();
          // $('#account_name').val();
          $('#email').val();
          $('#business_phone').val(this.param1);
          $('#mobile_phone').val();   
          $('#fax').val();   
          this.contact_name="Contact Details";
        }
        
    }, 
    (error)=>{
        console.log(error);
    });
    
    api_req_get_activity.apiFor="getPhoneCallActivity";
    api_req_get_activity.number=this.param1;
    api_req_get_activity.accessToken=this.MDy_bearer_token;
    this.serverService.MDy_Contacts_API(api_req_get_activity).subscribe((response:any) => {   

      // if(response.status==true){
        if(response.data != ""){
         this.phone_activity=response.data;
        //  console.log(this.phone_activity)
        //  this.phone_activity=this.phone_activity.reverse();
        //  console.log(this.phone_activity)

         this.recordNotFound = false;

        }else{
         this.recordNotFound = true;
        }
      
      
  }, 
  (error)=>{
      console.log(error);
  });
  });
}

AddContact(){
  let api_post_contact:any=new Object();
         let first_name= $('#first_name').val();
         let last_name = $('#last_name').val();
         let job_title=  $('#job_title').val();
          // $('#account_name').val();
         let email=  $('#email').val();
         let business_phone= $('#business_phone').val();
         let mobile= $('#mobile_phone').val();   
         let fax= $('#fax').val();  
         
         if(first_name == ""){
           iziToast.warning({
             message:"Please enter the First Name",
             position: "topRight"
           });
           return false;
         }
         if(last_name == ""){
          iziToast.warning({
            message:"Please enter the Last Name",
            position: "topRight"
          });
          return false;
        }
         api_post_contact.apiFor="AddNewContact";
         api_post_contact.accessToken=this.MDy_bearer_token;
         api_post_contact.Num=business_phone;
         api_post_contact.F_Name=first_name;
         api_post_contact.L_Name=last_name;
         api_post_contact.job_title=job_title;
         api_post_contact.email=email;
         api_post_contact.mobile=mobile;
         api_post_contact.fax=fax;
    this.serverService.MDy_Contacts_API(api_post_contact).subscribe((response:any) => {   

     
        if(response.data != "" && response.data != "null"){
          iziToast.success({
            message:"Contact details Added to Microsoft Dynamics",
            position:"topRight"
          });
          this.no_contact=false;
this.editConatcts();
        }else{
          iziToast.error({
            message:"Sorry,Server issue ouccred",
            position:"topRight"
          })
         this.recordNotFound = true;
        }
      
      
  }, 
  (error)=>{
      console.log(error);
  });
}



clictToCall(){
  // if(to == 'phone'){  this.to_num = $('#phone').val(); } else {  this.to_num = $('#mobile').val(); }
 
  // if(to == 'phone'){  this.to_num = this.click_to_call_number; } else {  this.to_num = this.click_to_call_mobile_number; }
  let to=$('#business_phone').val();
   if(to == ''){
       iziToast.warning({
         message: "No Number To Call",
         position: 'topRight'
       });
   } else {
 
 
     let access_token: any=localStorage.getItem('access_token');
   
     var extention = localStorage.getItem('ext_int_status');
     //alert(extention);
     if(extention == '2'){
      let api_reqs:any = '{"type": "makecall", "number": "'+to+'","show_caller_id":"'+this.show_caller_id+'"}';
      this.serverService.show.next(api_reqs);
     } else {
      let api_reqs:any = '{"type": "makecallauto", "number": "'+to+'"}';
      this.serverService.show.next(api_reqs);
     }
 
   }
   console.log(to);
 }

clictToCall2(){
  // if(to == 'phone'){  this.to_num = $('#phone').val(); } else {  this.to_num = $('#mobile').val(); }
 
  // if(to == 'phone'){  this.to_num = this.click_to_call_number; } else {  this.to_num = this.click_to_call_mobile_number; }
  let to=$('#mobile_phone').val();
   if(to == ''){
       iziToast.warning({
         message: "No Number To Call",
         position: 'topRight'
       });
   } else {
 
 
     let access_token: any=localStorage.getItem('access_token');
   
     var extention = localStorage.getItem('ext_int_status');
     //alert(extention);
     if(extention == '2'){
      let api_reqs:any = '{"type": "makecall", "number": "'+to+'","show_caller_id":"'+this.show_caller_id+'"}';
      this.serverService.show.next(api_reqs);
     } else {
      let api_reqs:any = '{"type": "makecallauto", "number": "'+to+'"}';
      this.serverService.show.next(api_reqs);
     }
 
   }
   console.log(to);
 }

 addWrapupcode(){
  let wrapcall_id=$('#wrapup_callID').val();
  let cat_id=$('#auxcodes_pop').val();
  let wraupcode=$('#auxcodes_subcat').val();
  let notes=$('#notes').val();
  let access_token: any=localStorage.getItem('access_token');
  let from_no;
  let to_no;

  var wrap = this.category_name +' -> '+ wraupcode;

if(this.paramCall == 'incoming')
{
  from_no=this.param1;
  to_no=this.extension;
}
else
{
  from_no=this.extension;
  to_no=this.param1;
}
//  notes=notes.toString().replace('"',' ');
notes = notes.toString().replaceAll(/"|'/g, '');


  // let api_req:any = '{"operation":"contact", "moduleType":"contact", "api_type": "web", 
  // "access_token":"'+access_token+'", "element_data":{"action":"add_auxcode_wall","from_no":
  // "'+from_no+'","to_no":"'+to_no+'","type":"'+this.paramCall+'","aux_code":"'+wraupcode+'",
  // "user_id":"'+this.uadmin_id+'","cat_id":"'+cat_id+'","call_note":"'+notes+'"}}';
  let api_req:any = new Object;
  let conct_req:any = new Object();

api_req.operation="contact";
api_req.moduleType="contact";
api_req.api_type="web";
api_req.access_token=localStorage.getItem('access_token');

conct_req.from_no=from_no;
conct_req.to_no=to_no;
conct_req.type=this.paramCall;
conct_req.aux_code=wraupcode;
conct_req.cat_id=cat_id;
conct_req.call_note=notes;
conct_req.user_id=this.uadmin_id;
api_req.element_data = conct_req;


api_req.element_data.admin_id=this.admin_id;
api_req.element_data.action="add_auxcode_wall";
  this.serverService.sendServer(api_req).subscribe((response:any) => {
    if(response.status==false){   

              } 
  }, 
  (error)=>{
      console.log(error);
  });
  // {"operation":"contact","moduleType":"contact","api_type":"web","access_token":"eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJvbW5pLmVudGVydGFpbm1lbnQuY29tLmF1IiwiYXVkIjoib21uaS5lbnRlcnRhaW5tZW50LmNvbS5hdSIsImlhdCI6MTYyMTA1ODcwOSwibmJmIjoxNjIxMDU4NzA5LCJleHAiOjE2MjEwNzY3MDksImFjY2Vzc19kYXRhIjp7InRva2VuX2FjY2Vzc0lkIjoiOTU4IiwidG9rZW5fYWNjZXNzTmFtZSI6ImFubmVzc2EiLCJ0b2tlbl9hY2Nlc3NUeXBlIjoiMiJ9fQ.vBc-2g4FoZC_xNb4dBUmQYKblkPL6l6wbI7WEes-xRI","element_data":{"action":"add_auxcode_wall","from_no":"1000","to_no":"1001","type":"outgoing","aux_code":"No Answer","user_id":"958"}}
  var socket_message  =  '[{"cust_id":"'+this.has_hard_id+'","data":[{"Name":"wrapupcode","callid":"'+wrapcall_id+'","wcode":"'+wrap+'","wcodenote":"'+notes+'"}]}]';
  this.websocket.send(socket_message);
  this.closeQuestion();
  iziToast.success({
    message:"Wrapup Code Added successfully",
    position:"topRight"
  });
  // $('#wrapup_callID').val('');
  $('#auxcodes_pop').val('');
  $('#auxcodes_subcat').val('');
  $('#notes').val('');
}
gettimedetails(act_id){

  let get_single_timeline:any= new Object();
  get_single_timeline.accessToken=this.MDy_bearer_token;
  get_single_timeline.activity_id=act_id;
  get_single_timeline.apiFor="get_single_timeline";
  this.serverService.MDy_Contacts_API(get_single_timeline).subscribe((response:any) => {   

    // if(response.status==true){
      if(response.data != ""){
      this.single_timeline=response.data[0];
      let Tdata=response.data[0];
      console.log(this.single_timeline);
     this.Tsubject=Tdata.subject;
     this.Tdescription=Tdata.description;
     this.Tphone=Tdata.phonenumber;
     this.Trecord=Tdata.new_recordingurl;
     this.Twrapcode=Tdata.new_wrapupcode;
     this.Twrapnote=Tdata.new_wrapupnote;
    //  alert(this.Trecord);
      //  this.phone_activity=this.phone_activity.reverse();
      //  console.log(this.phone_activity)
     $('#showpop_timeline').modal('show');
       this.recordNotFound = false;

      }else{
        this.Tsubject='';
        this.Tdescription='';
        this.Tphone='';
        this.Trecord='';
        this.Twrapcode='';
        this.Twrapnote='';
        iziToast.warning({
          message:'Sorry, cannot fetch timeline details',
          position:'topRight'
        })
       this.recordNotFound = true;
      }
    
    
}, 
(error)=>{
    console.log(error);
});
}

}
